import { Button, Descriptions, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ar from '../../localization/ar';
import en from '../../localization/en';
import Api from '../../Network/ApiConfig';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import ReactQuill from "react-quill";
import "./style.scss";

export default function AddTerms() {

    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const [descriptioncontent, setdescriptioncontent] = useState(EditorState.createEmpty());
    const [descriptionLTcontent, setdescriptionLTcontent] = useState(EditorState.createEmpty());
    const { TextArea } = Input;
    const location = useLocation();
    const {  currentLang } = useSelector((state) => state?.user);

    const t = currentLang === "en" ? en : ar;
    
    useEffect(() => {
      if (location?.state) {
        if (location?.state?.Description) {
            // const contentBlocks = draftToHtml(JSON.parse(location?.state?.Description || ""));
            // const contentBlocks = convertFromHTML(location?.state?.Description || "");
            // console.log(contentBlocks, "contentBlockscontentBlockscontentBlocks")
          try {
            const contentBlocks = convertFromHTML(JSON.parse(location?.state?.Description || ""));
            const contentState = ContentState.createFromBlockArray(contentBlocks);
            setdescriptioncontent(EditorState.createWithContent(contentState))
          } catch (error) {
            try {
              const contentBlocks = convertFromHTML(JSON.parse(JSON.stringify(location?.state?.Description || "")));
            const contentState = ContentState.createFromBlockArray(contentBlocks);
            setdescriptioncontent(EditorState.createWithContent(contentState))
            } catch (err) {
              console.log(err, "errrrrrrrrrrrrrrrrrrrrr")
            }
          }  
          }
        if (location?.state?.DescriptionLT) {
          try {
            const contentBlocks = convertFromHTML(JSON.parse(location?.state?.DescriptionLT || ""));
            const contentState = ContentState.createFromBlockArray(contentBlocks);
            setdescriptionLTcontent(EditorState.createWithContent(contentState))
          } catch (error) {
            try {
              const contentBlocks = convertFromHTML(JSON.parse(JSON.stringify(location?.state?.DescriptionLT || "")));
            const contentState = ContentState.createFromBlockArray(contentBlocks);
            setdescriptionLTcontent(EditorState.createWithContent(contentState))
            } catch (err) {
              console.log(err, "errrrrrrrrrrrrrrrrrrrrr")
            }
          }  
          }
        // if (location?.state?.DescriptionLT) {
        //           const contentBlocks = convertFromHTML(JSON.parse(location?.state?.DescriptionLT));
        //           const contentState = ContentState.createFromBlockArray(contentBlocks);
        //           setdescriptionLTcontent(EditorState.createWithContent(contentState))
        // }
        }
    }, [])
    

    const onFinish = (values) => {
        console.log(values);
        setLoading(true);

        
        let body = {
          Name: values?.Name,
          NameLT: values?.NameLT,
          FAQCategoryId: values?.FAQCategoryId,
          Description:values?.Description,
          DescriptionLT: values?.DescriptionLT
          // Description: draftToHtml(convertToRaw(descriptioncontent.getCurrentContent())).trim(),
          // DescriptionLT: draftToHtml(convertToRaw(descriptionLTcontent.getCurrentContent())).trim(),
        };


        if(location?.state){
          body.Id = location?.state?.Id;
          Api.post("AdminAPIs/EditTermsAndConditions", body).then(
            (res) => {
              console.log(res?.data?.metas?.message);
              setLoading(false);
  
              if(res?.Code){
                message.error(res?.message);
              }
              if(res?.status === 200){
          message.success(res?.data?.metas?.message);

                history.push({
                  pathname: '/terms',
              })
              }
              if(res?.status === 400){
                message.error(res?.errors?.message);
              }
            },
            (err) => {
              setLoading(false);
              console.log(err?.message)
              message.error(err?.message);
            }
          );
          return false;
        }else{
          Api.post("AdminAPIs/EditTermsAndConditions", body).then(
            (res) => {
              console.log(res?.data?.metas?.message);
              setLoading(false);
  
              if(res?.Code){
                message.error(res?.message);
              }
              if(res?.status === 200){
                message.success(res?.data?.metas?.message);
                
                history.push({
                  pathname: '/terms',
              })
                
              }
              if(res?.status === 400){
                message.error(res?.errors?.message);
              }
            },
            (err) => {
              setLoading(false);
              console.log(err?.message)
              message.error(err?.message);
            }
          );
        }

      };
      const modules = {
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          [{ color: [] }, { background: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
          ["clean"],
        ],
      };
    
      const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "background"
      ];

  return (
    <div className='terms-condition-wrapper'>
        <h2>{t.TermsAndConditions}</h2>
        <div className="form-inputs-wrapper">
        <Form form={form} name="personalInfo" className='form__wrapper form__wrapper--product'
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            Name: location?.state?.Name,
            NameLT: location?.state?.NameLT,
            Description: location?.state?.Description,
            DescriptionLT: location?.state?.DescriptionLT,
          }}
        >
<div className="form-grid-holder">

<Form.Item
                        label={t.nameAr}
                        name="NameLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>



                    <Form.Item
                        label={t.nameEn}
                        name="Name"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t.descriptionAr}
                        name="DescriptionLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                        className="full-width"
                    >
                        {/* <TextArea rows={8} /> */}
                        {/* <Editor
                        locale="ar"
                        toolbar={{
                          options: ['inline', 'blockType', 'textAlign', 'link', 'remove', 'history'],
                          inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                          },
                        }}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorState={descriptionLTcontent}
                        onEditorStateChange={(val) => setdescriptionLTcontent(val)}
                      /> */}
                                          <ReactQuill
                  theme="snow"
                  className="quill-content-holder quill-content-input-holder rtl-input"
                  style={{
                    width: '100%', /* or any specific width like '600px' */
                    height: '600px', /* or any specific height */
                    maxWidth: '100%', /* Ensure the video doesn't overflow its container */
                }}
                modules={modules}
                formats={formats}
                />
                    </Form.Item>
                    <Form.Item
                        label={t.descriptionEn}
                        name="Description"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                        className="full-width"

                    >
                        {/* <TextArea rows={8} /> */}
                    
                      {/* <Editor
                      toolbar={{
                        options: ['inline', 'blockType', 'textAlign', 'link', 'remove', 'history','colorPicker'],
                        inline: {
                          options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        colorPicker: { // Enable color options
                          colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)', 'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)', 'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)', 'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)', 'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)', 'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                        },
                      }}
                        wrapperClassName="demo-wrapper en-editor-style"
                        editorClassName="demo-editor"
                        onEditorStateChange={(val) => setdescriptioncontent(val)}
                        editorState={descriptioncontent}
                      /> */}
                                      <ReactQuill
                  theme="snow"
                  className="quill-content-holder quill-content-input-holder"
                  style={{
                    width: '100%', /* or any specific width like '600px' */
                    height: '600px', /* or any specific height */
                    maxWidth: '100%', /* Ensure the video doesn't overflow its container */
                    direction:'ltr'
                }}
                modules={modules}
                formats={formats}
                />
                    </Form.Item>
                    </div>

                    <Form.Item style={{ marginTop : 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', flexBasis: '100%' }}>
                            <Button loading={loading} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
                        </div>
                    </Form.Item>
{/* 
          <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
              <Button loading={btnLoading} onClick={() => setTimeout(() => {
                document.querySelector('.ant-form-item-has-error')?.scrollIntoView(false)
              }, 50)} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
              <Button loading={btnLoading} onClick={() => setTimeout(() => {
                document.querySelector('.ant-form-item-has-error')?.scrollIntoView(false)
              }, 50)} htmlType='submit' style={{ background: '#fff', color: "#fff" }}> التالي <ArrowLeftOutlined /> </Button>
            </div>
          </Form.Item> */}
        </Form>
        </div>
    </div>
  )
}
